<section class="header container-fluid fixed_top">
    <div class="row d-flex align-items-center py" #header>

        <div class="header_logo_flx">

            <div class="application-logo">
                <img src="assets/images/common/logo.svg" class="" alt="logo">
            </div>

            <div class="">
                <div class="mb-0  fw-300 d_flx_rwanda">
                    <span class="username">
                        <img src="assets/images/common/rwanda_logo.svg" class="" alt="logo">
                    </span>
                    <span class="rwanda_img">{{staticText?.common?.company_secondary_name || "Rwanda Digital Tax
                        Stamp System"}}</span>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="login-screen">
    <div class="login_div_two" *ngIf="!showResetPasswordPage">

        <div class="login_left_usp ">
            <div>
                <span class="welcome_msg">{{staticText?.login?.login_welcome_msg}}</span>

                <p class="my-3 login_left_description">{{staticText?.login?.login_page_description}}</p>
            </div>
        </div>
        <div class="login_ryt_usp right-section postion_relative_full">

            <div class="form_login">
                <div class="d-flex flex-row justify-content-start align-items-center">

                    <label class="login_txt "
                        [innerHtml]="staticText?.forgot_password?.forgot_password_title_1"></label>
                </div>

                <div class="" >
                    <p class="my-2 text-sm font-normal login_ryt_description"
                        [innerHtml]="!showForgotOTP ? staticText?.forgot_password?.forgot_password_paragraph_1 : staticText?.forgot_password?.forgot_password_paragraph_2">
                    </p>
                </div>

                <ng-container *ngIf="!showForgotOTP">
                    <div class=" ">
                        <form [formGroup]="forgotForm" class=" d-flex flex-column forgot_btm">
                            <div class="mt-4 my-2">
                                <custom-input
                                    [inputPlaceholderLabel]="staticText?.forgot_password?.forgot_password_input"
                                  
                                    [inputFormGroup]="forgotForm" inputFormControlName="email"
                                    [inputRequiredStatus]="true" [inputAppearance]=""
                                    [inputLabel]="staticText?.forgot_password?.forgot_password_input"
                                    >
                                </custom-input>
                            </div>
                            <div>

                                <div class=" forgot_div">
                                    <a (click)="redirectHome()" (click)="navigate()" class="forgot_txt">Back to
                                        Login</a>

                                    <button mat-raised-button class="btn_login"
                                        [ngClass]="{'cursor-not-allowed': forgotForm.invalid}"
                                        (click)="forgotPassword($event)" [disabled]="forgotForm.invalid"
                                        [innerHtml]="staticText?.common?.submit_button_txt">
                                    </button>



                                </div>
                                
                            <div id="h_full_reset" [style.display]="showFullReset ? 'block' : 'none'">
                                The reset link has been sent to your registered email id:
                                <span class="email_bold">{{ emailInputValue }}</span>
                            </div>



                            </div>



                        </form>





                    </div>
                </ng-container>
                <ng-container *ngIf="showForgotOTP && !showForgotOTPSuccessScreen">
                    <div class="container-fluid">
                        <form [formGroup]="forgotOtpForm" class="d-flex flex-column">
                            <div class="row">

                                <custom-input [inputFormGroup]="forgotOtpForm" inputFormControlName="otp_input"
                                    inputType="text" inputMaxLength="6">
                                </custom-input>

                            </div>
                            <div class=" fs-16">
                                <button mat-raised-button
                                    class="w-100 bg-color-primary text-white verify-btn cursor-pointer"
                                    (click)="verifyOTP()" [disabled]="forgotOtpForm.invalid"
                                    [innerHtml]="staticText?.forgot_password?.forgot_password_verify_button">
                                </button>
                            </div>
                        </form>
                    </div>

                </ng-container>


                <ng-container *ngIf="showForgotOTPSuccessScreen">
                    <!-- <div class="py-4 px-2">
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <i class="fa-solid fa-circle-check f-27 color-green pe-2"></i>
                            <p class="fs-14 fa-custom-regular color-primary mb-0"
                                [innerHtml]="staticText?.forgot_password?.forgot_password_verified_success_msg">
                            </p>
                        </div>
                    </div> -->

                </ng-container>
            </div>
        </div>
    </div>
</section>
<footer class="footer_fixed">
    <span class="text-sm text-black">2024© All rights reserved</span>
</footer>
