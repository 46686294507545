import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SCREENMODE } from 'src/app/core/services/utils/constants';
import { ResetpasswordSuccessComponent } from './resetpassword-success/resetpassword-success.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'password-success',
    component: ResetpasswordSuccessComponent
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent
  },
  {
    path: 'change-password',
    component: ResetPasswordComponent,
    data: {
      mode: SCREENMODE.CHANGE_PASSWORD
    }
  },
  {
    path: 'reset',
    component: ResetPasswordComponent,
    data: {
      mode: SCREENMODE.RESET_PASSWORD
    }
  },
  {
    path: 'signup',
    component: SignUpComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }


]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
