import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { colorCodes, ErrorCodes, routePath } from 'src/app/core/services/utils/constants';
import { environment } from 'src/environments/environment';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { Subscription } from 'rxjs';




@Component({
  standalone: false,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  appearenceoutline: any;
  mailid: any = 'Email ID';
  passwordid: any = 'Password';
  userEmail: string = "";

  loginForm: FormGroup | any;
  staticText: any = (textConfiguration as any).default;
  enableCaptcha: boolean = false;
  currentYear: number = new Date().getFullYear();
  loginFailed: boolean = false;
  showOTPForm: boolean = true;
  otpValue: any = '';
  isOtpValid: boolean = false;
  routePath: any = routePath;
  landingUrl = environment.websiteLandingUrl;
  parentFlag: boolean = false;
  // For OTP verisication...........................
  enableLoginOtp: boolean = environment.enableLoginOtp;
  otpProgress: boolean = false;
  @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput!: NgOtpInputComponent;
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  otpInputFormEleRef:any;
  showNewUserRegn: boolean = true;
  showPassword:boolean = false;
 
  timer: any;
  minutes: string = "";
  seconds: string = "";
  loginFormSection: boolean = true;
  otpFormSection: boolean = false;
  resendOtpBtn:boolean = false;
  verifyOtpBtn:boolean = true;
  loginErrorValidation!:Subscription;
  @ViewChild('minutesSpan') minutesSpan1!: ElementRef<HTMLElement>;
  @ViewChild('secondsSpan') secondsSpan1!: ElementRef<HTMLElement>;
  @ViewChild('timerDiv') timerDiv1!: ElementRef<HTMLElement>;
  // ...........................For OTP verisication
  constructor(
    private _authService: AuthService,
    private _formValidator: FormValidatorService,
    private _formbuilder: FormBuilder,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private _storage: StorageService,
    private activated_route: ActivatedRoute,
    private _loader: LoaderService,
    private _common: CommonService,
    private _snackBar: SnackbarService,
    private router: Router
  ) {
    clearInterval(this.timer);
  }
  

  ngOnInit(): void {
    // this._common.getSetRouteValue(this.activated_route?.data);
    this.enableCaptcha = environment?.enableCaptcha
    if (this._authService.isLoggedIn()) {
      let permission: any = this._storage.getPermssions();
      localStorage.removeItem('activeMenu')
      this._router.navigate([routePath?.USER_DEFAULT_LIST_PATH]);
    } else {
      this.initLoginform();
    }

    this._authService.loginFailed.subscribe((data: any) => {
      this.loginFailed = data;
      this.otpValue = ''
      this.cdr?.detectChanges();
    });
    this._authService.isOTPRequired.subscribe((data: any) => {
      if (data) {
        this.showOTPForm = true
      }
      this.cdr?.detectChanges();
    });
    this.loginErrorValidation = this._authService.loginErrorValidation.subscribe((data: any) => {
      if (data?.error?.message.includes("user does not exist")) 
        this.loginForm.get('username').setErrors({ validation_message: data?.error?.message });
      else
        this.loginForm.get('password').setErrors({ validation_message: data?.error?.message });
      this.cdr?.detectChanges();
    });
   
    clearInterval(this.timer);
  }

  ngAfterViewInit(){
    this.otpInputFormEleRef = this.ngOtpInput.otpForm;
    // console.log("OTP-FORM",this.otpInputFormEleRef.disable());
  }

  showHidePassword(ev:any){
    ev.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  navigateSelfRegister() {
    console.log(routePath?.SELF_REGISTER_PATH);
    return this._router.navigate([routePath?.SELF_REGISTER_PATH]);
  }

  initLoginform() {
    this.loginForm = this._formbuilder.group({
      recaptcha: [!environment?.enableCaptcha, [this._formValidator.requiredValidationCheck('Recaptcha')]],
      username: ["", []],
      password: ["", []],
    });
  }

  onOtpChange(otp: any) {
    this.isOtpValid = false;
    this.loginFailed = false;
    if (otp.length == 6) {
      this.otpValue = otp;
      this.isOtpValid = true;

    }
  }

  SpaceValidator(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
      return { required: true }
    }
    else {
      return null;
    }
  }

  doLogin() {
    let loginPayload: any = {
      username: this.loginForm.value?.username,
      password: this.loginForm.value?.password,
    };

    if (this.enableLoginOtp) {
      if (this.isOtpValid) {
        loginPayload = { ...loginPayload, ...{ otp: this.otpValue } }
      } else {
        this._snackBar.loadSnackBar("Enter the valid OTP", colorCodes.WARNING);
        return;
      }
    }

    this._authService.login(loginPayload, this.enableLoginOtp)

  }

  onCaptchaResolved(captchaResponse: any) {
    if (captchaResponse) {
      this.loginForm.get('recaptcha').setValue(true);
    }

  }

  onCaptchaErrored(captchaError: any) {
    this.loginForm.get('recaptcha').setValue('');
  }
  navigation() {
    this._router.navigate(['/auth/signup']);
  }
  redirectForgot() {
    this._router.navigate(['/auth/forgot']);

  }

  /**
   * @description
   * OTP Generate API...................
   * @param ev 
   */
  sendOtp(ev?: any) {
    let loginPayload: any = {
      username: this.loginForm.value?.username,
      password: this.loginForm.value?.password,
    }
    this._loader.show();
    this._authService.sendOTP(loginPayload).subscribe((otpRes: any) => {
      this._loader.hide();
      if (otpRes?.code == ErrorCodes.HTTP_200_SUCCESS) {
        this._snackBar.loadSnackBar(otpRes?.data?.detail, colorCodes.SUCCESS);
        this.userEmail = otpRes?.data?.email;
        this.otpInputFormEleRef.enable();
        this.timeDivision([]);
        this.otpProgress = true;
        this.showOTPForm = true;
        this.loginFormSection = false;
        this.otpFormSection = true;
        this.cdr?.detectChanges();
        // this._router.navigate(['/auth/otp']);
      }
    });
  }
  checkRouteForNewUser() {
    // const currentUrl = this.router.url;
    // if (currentUrl === '/scp/auth/login') {
    //   this.showNewUserRegn = true;
    // } else {
    //   this.showNewUserRegn = false;
    // }
  }

  hasPasswordValue(): boolean {
    return this.loginForm?.get('password')?.value?.length > 0;
  }

  showLoginForm() {
    this.otpFormSection = false;
    this.loginFormSection = true;
    this.otpProgress = false
  }

  
  timeDivision(data: any){
    if (!data["errors"]) {
      let otpSentTime = new Date().toISOString();
      localStorage.setItem("otp_sent_time", String(otpSentTime));     
      this.verifyOtpBtn = true;
      this.resendOtpBtn = false;
      // initial set up for timer
      this.setTimer();
      if (this.timerDiv1) {
        this.timerDiv1.nativeElement.style.visibility = "visible";
      }
      if (this.secondsSpan1) {
        this.secondsSpan1.nativeElement.innerText = this.seconds;
      }
      if (this.minutesSpan1) {
        this.minutesSpan1.nativeElement.innerText = this.minutes;
      }
      this.timer = setInterval(this.timerFn, 1000);
    }
  }

  setTimer() {
    const time = Number(environment.otpDuration * 60);

    if (!isNaN(time)) {
      const minutes = time / 60;
      const seconds = time % 60;
      if (minutes < 10) {
        this.minutes = "0" + minutes;
      } else {
        this.minutes = String(minutes);
      }
      if (seconds < 10) {
        this.seconds = "0" + seconds;
      } else {
        this.seconds = String(seconds);
      }
    } else {
      this.minutes = "02";
      this.seconds = "00";
    }
  }

  timerFn = () => {

    let secValue, minValue = 0;

    let timeData = this.setSecondsAndMunites(secValue,minValue)
    secValue = timeData.secValue
    minValue = timeData.minValue
    if (secValue === 0) {
      secValue = 60;
      if(minValue === 0) {
        this.hideTimer();
        return;
      }
      if (this.minutesSpan1?.nativeElement.innerText) {
        this.minutesSpan1.nativeElement.innerText = "0" + (minValue - 1);
      }
    }

    if (this.secondsSpan1?.nativeElement.innerText) {
      if (secValue === 10 || secValue < 10) {
        this.secondsSpan1.nativeElement.innerText = "0" + --secValue;
      } else {
        this.secondsSpan1.nativeElement.innerText = --secValue + "";
      }
    }
  };

  setSecondsAndMunites(secValue:any, minValue:any) {

    if (
      this.timerDiv1.nativeElement?.style.visibility == "visible" &&
      this.secondsSpan1.nativeElement?.innerText &&
      this.minutesSpan1.nativeElement 
    ) {
      secValue = Number(this.secondsSpan1.nativeElement.innerText);
      minValue = Number(this.minutesSpan1.nativeElement.innerText);
    } else {
      secValue = Number(this.seconds);
      minValue = Number(this.minutes);
      if (this.timerDiv1) {
        this.timerDiv1.nativeElement.style.visibility = "visible";
      }
    }
    return {
      secValue:secValue,
      minValue:minValue
    }
  }

  hideTimer(): void {
      // redirecting to initial phase on completion of timer
      if (this.minutesSpan1) {
        this.minutesSpan1.nativeElement.innerText = this.minutes
      }
      if (this.timerDiv1) {
        this.timerDiv1.nativeElement.style.visibility = "hidden";
        this.verifyOtpBtn = false;
        this.resendOtpBtn = true;
        this.ngOtpInput.setValue(0);
        this.otpInputFormEleRef.disable();
        this.cdr?.detectChanges();
      }
      clearInterval(this.timer);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.loginErrorValidation.unsubscribe();
  }

}
