<div class="otp-container">
<div #timerDiv id="timer" [hidden]="!showTimerContainer">
  <span class="resend_font">Resend in: </span>
  <span class="resend_font" #minutesSpan id="minutesSpan">{{ minutes }}</span>
  <span class="resend_font">:</span>
  <span class="resend_font" #secondsSpan id="secondsSpan">{{ seconds }}</span>
</div>
    
<a *ngIf="resendOtpBtn" style="text-decoration: none;" (click)="resendOtp()" href="javascript:;">Resend OTP</a>
</div>
