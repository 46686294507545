import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';

@Component({
  selector: 'app-resetpassword-success',
  templateUrl: './resetpassword-success.component.html',
  styleUrls: ['./resetpassword-success.component.scss'],
})
export class ResetpasswordSuccessComponent {
  staticText: any = (textConfiguration as any).default;

  constructor(private router: Router) { }

  loginpage() {
    console.log('Navigating to login page');
    this.router.navigate(['/auth/login']);
  }
}
