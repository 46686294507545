<section class="login-screen users-view-edit-page">
    <mat-card class="card-default-style">
        <mat-card-title class="text-capitalize px-1 pb-2 fs-20">
            <div class="d-inline-flex flex-row justify-content-start align-items-center fs-16 fw-600">
                <p class="text-capitalize mb-0">
                    Signup
                    <!-- {{ staticText?.user_management?.user?.view_edit?.add_page_title_4  }} -->
                </p>
            </div>
        </mat-card-title>
        <mat-divider></mat-divider>

        <mat-card-content class="mb-0" >
            <div class="container-fluid">
                <form [formGroup]="usersForm">
                    <div class="row input_autocomplete">
                        <div class="col-12 row row-cols-3 py-4 px-3">
                            <div class="col">
                                <custom-input [inputFormGroup]="usersForm" inputFormControlName="username"
                                    [inputRequiredStatus]="true" 
                                    [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_1"
                                    [inputPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_1"
                                    [inputErrorLabel]="usersForm.get('username').errors?.validation_message">
                                </custom-input>
                            </div>
                            <div class="col">
                                <custom-input [inputFormGroup]="usersForm" inputFormControlName="first_name"
                                    [inputRequiredStatus]="true"
                                    [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_2"
                                    [inputPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_2"
                                    [inputErrorLabel]="usersForm.get('first_name').errors?.validation_message">
                                </custom-input>
                            </div>
                            <div class="col">
                                <custom-input [inputFormGroup]="usersForm" inputFormControlName="last_name"
                                    [inputRequiredStatus]="true"
                                    [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_3"
                                    [inputPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_3"
                                    [inputErrorLabel]="usersForm.get('last_name').errors?.validation_message">
                                </custom-input>
                            </div>
                            <div class="col">
                                <custom-input [inputFormGroup]="usersForm" inputType="email" inputFormControlName="email"
                                    [inputRequiredStatus]="true"
                                    [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_4"
                                    [inputPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_4"
                                    [inputErrorLabel]="usersForm.get('email').errors?.validation_message">
                                </custom-input>
                            </div>
                            <div class="col">
                                <custom-date-picker [inputDatePickerFormGroup]="usersForm"
                                    inputDatePickerFormControlName="date_of_birth" [inputDatePickerRequiredStatus]="false"
                                    [inputDatePickerLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_5"
                                    [inputDatePickerPlaceHolder]="staticText?.user_management?.user?.view_edit?.view_edit_input_5"
                                    [inputDatePickerMaxDate]="currentDate"
                                    [inputDatePickerErrorLabel]="usersForm.get('date_of_birth').errors?.validation_message">
                                </custom-date-picker>
                            </div>
                            <div class="col"> 
                                <custom-input-dropdown [inputDropdownFormGroup]="usersForm" 
                                inputDropdownFormControlName="country_code"
                                inputFormControlName="phone_number"
                                [inputLabel]="staticText?.user_management?.user?.view_edit?.view_edit_input_6"
                                [inputDropdownRequiredStatus]="false"
                                [dropdownReadonly]="true"
                                [inputDropdownPreffix]="true"
                                [inputRequiredStatus]="true"
                                inputType="text" [inputMaxLength]="phoneNumberMaxLength"
                                [inputDropdownreadonly]="true"
                                [inputOnKeyPressFunction]="_common.acceptNumberOnlyEvent"
                                [inputDropdownArray]="countries" 
                                [inputDropdownPlaceholder]="staticText?.common?.country_code"
                                [inputPlaceholder]="staticText?.user_management?.user?.view_edit?.view_edit_input_6"
                                [inputErrorLabel]="usersForm.get('phone_number').errors?.validation_message"
                                >
                            </custom-input-dropdown>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </mat-card-content>
        <ng-container >
            <mat-divider></mat-divider>
            <mat-card-actions align="end" class="py-2">
                <div class="d-flex flex-row justify-content-around align-items-center text-capitalize cursor-pointer px-2">
                    <div class="p-2">
                        <button class="cancel-default-style" mat-stroked-button
                        (click)="_routeService.navigatePages(routePath?.AUTH_LOGIN_REDIRECT_PATH)"
                            [innerHtml]="staticText?.common?.cancel_button"></button>
                    </div>
                    <div class="p-2">
                        <button mat-raised-button class="create-default-style" (click)="submitUsers()">
                            <div>
                                <span  class="text-dark fw-bold">Signup</span>
                                <img src="/assets/images/common/icons/arrow_right_dark.svg" alt="button_icon"
                                    class="mx-2">
                            </div>
                        </button>
                    </div>
                </div>
              
               
                
            </mat-card-actions>
        </ng-container>
    </mat-card>

    <div class="row footer">
            <div class="copyrights  col-12">
                <small>{{staticText?.login?.login_copyrights_year}} {{currentYear}}
                    {{staticText?.login?.login_copyrights}}</small>
            </div>
        </div>
    
</section>