<section class="header container-fluid fixed_top">
    <div class="row d-flex align-items-center py" #header>

        <div class="header_logo_flx">

            <div class="application-logo">
                <img src="assets/images/common/logo.svg" class="" alt="">
            </div>

            <div class="">
                <div class="mb-0  fw-300 d_flx_rwanda">
                    <span class="username">
                        <img src="assets/images/common/rwanda_logo.svg" class="" alt="">
                    </span>
                    <span class="rwanda_img">{{staticText?.common?.company_secondary_name || "Rwanda Digital Tax
                        Stamp System"}}</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="login-screen">
    <div class="login_div_two">

        <div class="login_left_usp ">
            <div>
                <span class="welcome_msg">{{staticText?.login?.login_welcome_msg}}</span>
                <p class="my-3 login_left_description">{{staticText?.login?.login_page_description}}</p>

            </div>
        </div>
        <div class="login_ryt_usp">
            <div class="form_login_password h_full ">
                <div class="password_successful h_full">
                   
                    <img class="mb-3" src="../../../../assets/images/common/login_page_usp/tick_icn.png" alt="">
                    <p class="login_txt_password">Password reset successful</p>
                    <p class="new_portal_txt_center">Great news! Your password has been successfully
                        updated. You can now log in securely with your
                        new password.</p>
                    <button mat-raised-button class="btn_login" (click)="loginpage()">{{staticText?.login?.login_page}}</button>
            
                </div>
            
            
            
            
            
            
            
            
            </div>


           
        </div>
    </div>
</section>
<footer class="footer_fixed">
    <span class="text-sm text-black">2024© All rights reserved</span>

</footer>