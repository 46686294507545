import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { ApiMethod, Endpoints, SuccessMessage, colorCodes, routePath } from 'src/app/core/services/utils/constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup | any;
  forgotOtpForm: FormGroup | any;
  showForgotOTP: boolean = false;
  showForgotOTPSuccessScreen: boolean = false;
  showResetPasswordPage: boolean = false;
  staticText: any = (textConfiguration as any).default;
  routePath: any = routePath;
  userDetails: any;
  userToken: any;
  showFullReset: boolean = false; // Control display of h_full_reset
  emailInputValue: string = ''; // Store entered email

  constructor(
   
    private formBuilder: FormBuilder,
    private router: Router,
    private _loader: LoaderService,
    private _snackBar: SnackbarService,
    private _formValidator: FormValidatorService,
    private _http: HttpService,
    private cdRef: ChangeDetectorRef // Added ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initialDependencies();
  }

  initialDependencies() {
    this.createForm();
    this.otpForm();
  }

  createForm() {
    this.forgotForm = this.formBuilder.group({
      email: [null, [this._formValidator.requiredValidationCheck('Email')]],
    });
  }

  otpForm() {
    this.forgotOtpForm = this.formBuilder.group({
      otp_input: [null, [this._formValidator.requiredValidationCheck('')]],
    });
  }

  forgotPassword(data: any) {
    this._loader.show();
    this._http.requestCall(Endpoints.POST_FORGOT_PASSWORD, ApiMethod.POST, this.forgotForm.value)
      .subscribe((response: any) => {
        this._loader.hide();

        if (response && response.status === 'success') {
          this.userDetails = response;
          this.emailInputValue = this.forgotForm.get('email')?.value || '';
          this.showFullReset = true; // Display h_full_reset after success
          this.cdRef.detectChanges(); // Trigger change detection
          this._snackBar.loadSnackBar(SuccessMessage.RESENT_LINK, colorCodes.SUCCESS);
          
          
          this.forgotForm.get('email')?.reset();



        } else {
          this.showFullReset = false; // Hide if response is unsuccessful
        }

        this.showForgotOTPSuccessScreen = true;
      }, (error: any) => {
        this._loader.hide();
        console.error("Error in forgotPassword:", error);
        this.showFullReset = false;
      });
  }

  navigate() {
    this.router.navigate(['/auth/login']);
  }

  resetPassword() {
    this.router.navigate([routePath?.RESET_REDIRECT_PATH + this.userToken?.token]);
    this.showResetPasswordPage = true;
  }
}
