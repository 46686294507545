import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
@Component({
  standalone: false,
  selector: 'app-otp-timer-resend',
  templateUrl: './otp-timer-resend.component.html',
  styleUrl: './otp-timer-resend.component.scss',

})
export class OtpTimerResendComponent implements OnDestroy  {
  staticText: any = (textConfiguration as any).default;
  @Output() onResendOtpHandler = new EventEmitter<any>();
  timer: any;
  minutes: string = "";
  seconds: string = "";
  showTimerContainer:boolean = false;
  resendOtpBtn:boolean = false;
  @ViewChild('minutesSpan') minutesSpan1!: ElementRef<HTMLElement>;
  @ViewChild('secondsSpan') secondsSpan1!: ElementRef<HTMLElement>;
  @ViewChild('timerDiv') timerDiv1!: ElementRef<HTMLElement>;
  
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { 
    clearInterval(this.timer);
  }

  showTimer(): void {
    clearInterval(this.timer);
    this.startTimer();
  }

  resendOtp(): void {
    this.onResendOtpHandler.emit("resend");
    
  }

  startTimer(){
    
      let otpSentTime = new Date().toISOString();
      localStorage.setItem("otp_sent_time", String(otpSentTime));     
      this.resendOtpBtn = false;
      // initial set up for timer
      this.setTimer();
      this.toggleTimerDiv(true);
      if (this.secondsSpan1) {
        this.secondsSpan1.nativeElement.innerText = this.seconds;
      }
      if (this.minutesSpan1) {
        this.minutesSpan1.nativeElement.innerText = this.minutes;
      }
      this.timer = setInterval(this.timerFn, 1000);
    
  }

  setTimer() {
    const time = Number(environment.otpDuration * 60);

    if (!isNaN(time)) {
      const minutes = time / 60;
      const seconds = time % 60;
      if (minutes < 10) {
        this.minutes = "0" + minutes;
      } else {
        this.minutes = String(minutes);
      }
      if (seconds < 10) {
        this.seconds = "0" + seconds;
      } else {
        this.seconds = String(seconds);
      }
    } else {
      this.minutes = "02";
      this.seconds = "00";
    }
  }

  timerFn = () => {

    let secValue, minValue = 0;

    let timeData = this.setSecondsAndMunites(secValue,minValue)
    secValue = timeData.secValue
    minValue = timeData.minValue
    if (secValue === 0) {
      secValue = 60;
      if(minValue === 0) {
        this.hideTimer();
        return;
      }
      if (this.minutesSpan1?.nativeElement.innerText) {
        this.minutesSpan1.nativeElement.innerText = "0" + (minValue - 1);
      }
    }

    if (this.secondsSpan1?.nativeElement.innerText) {
      if (secValue === 10 || secValue < 10) {
        this.secondsSpan1.nativeElement.innerText = "0" + --secValue;
      } else {
        this.secondsSpan1.nativeElement.innerText = --secValue + "";
      }
    }
  };

  setSecondsAndMunites(secValue:any, minValue:any) {

    if (
      this.timerDiv1.nativeElement?.style.visibility == "visible" &&
      this.secondsSpan1.nativeElement?.innerText &&
      this.minutesSpan1.nativeElement 
    ) {
      secValue = Number(this.secondsSpan1.nativeElement.innerText);
      minValue = Number(this.minutesSpan1.nativeElement.innerText);
    } else {
      secValue = Number(this.seconds);
      minValue = Number(this.minutes);
      this.toggleTimerDiv(true);
    }
    return {
      secValue:secValue,
      minValue:minValue
    }
  }

  hideTimer(): void {
      // redirecting to initial phase on completion of timer
      if (this.minutesSpan1) {
        this.minutesSpan1.nativeElement.innerText = this.minutes
      }
      
      this.toggleTimerDiv(false);
      clearInterval(this.timer);
  }

  toggleTimerDiv(showTimer: boolean) {
    if (this.timerDiv1) {
      if(showTimer) {
        this.showTimerContainer = true;
        this.timerDiv1.nativeElement.style.visibility = "visible";
      } else {
        this.timerDiv1.nativeElement.style.visibility = "hidden";
        this.showTimerContainer = false;
        this.resendOtpBtn = true;
        this.cdr?.detectChanges();
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer)
  }


}
