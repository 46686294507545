
import { Component} from '@angular/core';
import {  FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { routePath, Endpoints, ApiMethod, SuccessMessage, colorCodes, FailedMessage, SCREENMODE } from 'src/app/core/services/utils/constants';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent {
  email: string = '';

  resetPasswordForm: any;

  resetToken: any;
  routePath: any = routePath;
  staticText: any = (textConfiguration as any).default;

  showForm: boolean = true;

  measureStrength: any;
  passwordStrength: number = 0;

  strengthLabel = this.staticText?.reset_password?.reset_password_strength_label
  token: any;
  pageMode: any;
  screenmode:any = SCREENMODE;
  showPassword:boolean = false;
  showConfirmPassword:boolean = false;

  constructor(
    private _authService: AuthService,
    private _http: HttpService,
    protected _router: RouterService,
    private formBuilder: FormBuilder,
    private _loader: LoaderService,
    private _snackBar: SnackbarService,
    private _formValidator: FormValidatorService,
    private route: ActivatedRoute,
    private router: Router,
    private _common: CommonService,
    private activated_route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.getRouteParams();
    this.initialDependencies();
    
  }

  showHidePassword(ev:any){
    ev.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  showHideConfirmPassword(ev:any){
    ev.stopPropagation();
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  getRouteParams() {
    const routeInfo: any = this.activated_route?.data;
    this.pageMode = routeInfo?.value?.mode;
    if (this.pageMode == this.screenmode.RESET_PASSWORD) {
      this.verifyResetToken();
    } else {
      this.showForm = true
    }
  }

  initialDependencies() {
    this.createForm();

  }

  getResetTokenVerification() {
    this.resetToken = this._router.getResetPasswordToken()
  }

  redirectLogin(){
    this._authService.logout();
  }
  
  createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      new_password: [null, [this._formValidator.passwordValidationCheck, this._formValidator.minMaxLengthCheck(8, 12)]],
      confirm_password: [null],
    });
  
    // Custom validation for confirm_password
    this.resetPasswordForm.setValidators(
      this._formValidator.confirmPasswordValidationCheck('new_password', 'confirm_password')
    );
  
    // Listening to value changes in the 'new_password' field
    this.resetPasswordForm.get('new_password')?.valueChanges.subscribe((password: any) => {
      this.measureStrength = password;
    });
  }
  

  verifyResetToken() {
    this._loader.show();
    const payload = { token: this._router.getResetPasswordToken() }
    this._http.requestCall(Endpoints.POST_PASSWORD_RESET_VERIFY, ApiMethod.POST, payload).subscribe((tokenStatus: any) => {
      this._loader.hide();
      if (tokenStatus) { this.showForm = true } else { this.showForm = false }
    }, (error: any) => {
      this._loader.hide();
      this.showForm = false;
    })
  }

  resetPassword() {
    if (this.pageMode == SCREENMODE.RESET_PASSWORD) {
      this.resetPasswordFromMail()
    }else if (this.pageMode == SCREENMODE.CHANGE_PASSWORD) {
      this.initialResetPassword()
    }
   

  }

  initialResetPassword() {
    this._loader.show();
    const payload = {
      "new_password": this.resetPasswordForm.value.new_password
    }
    this._http.requestCall(Endpoints.POST_PASSWORD_UPDATE, ApiMethod.POST, payload).subscribe((resetStatus: any) => {
      if (resetStatus) {
        this._loader.hide();
        this._snackBar.loadSnackBar(SuccessMessage.RESET_PASSWORD_MSG, colorCodes.SUCCESS);
        this.router.navigate(['/auth/login']);
      }
      else {
        this._loader.hide();
        this._snackBar.loadSnackBar(FailedMessage.RESET_PASSWORD_FAILED_MSG, colorCodes.ERROR);
      }
    })
  }
  login_page(){
    
    this._router.navigate(['/auth/login']);

  }



  resetPasswordFromMail() {
    this._loader.show();
    const payload = {
      "password": this.resetPasswordForm.value.new_password,
      "token": this._router.getResetPasswordToken()
    }
    this._http.requestCall(Endpoints.POST_PASSWORD_RESET, ApiMethod.POST, payload).subscribe((resetStatus: any) => {
      if (resetStatus) {
        this._loader.hide();
        this._snackBar.loadSnackBar(SuccessMessage.RESET_PASSWORD_MSG, colorCodes.SUCCESS);
        this.router.navigate(['/auth/password-success']);
      }
      else {
        this._loader.hide();
        this._snackBar.loadSnackBar(FailedMessage.RESET_PASSWORD_FAILED_MSG, colorCodes.ERROR);
      }
    })
  }
  hasPasswordValue(): boolean {
    return this.resetPasswordForm?.get('new_password')?.value?.length > 0;
  }
  hasshowPasswordValue(): boolean {
    return this.resetPasswordForm?.get('confirm_password')?.value?.length > 0;
  }

}
